import {Link} from 'react-router-dom'
function Home() {
    return (
      <>
        <main>
          <h2>Welcome to Serpent Academy!</h2>
          <p>Study as hard as you can, Every lesson studied you will earn $serpent tokens.</p>
          <p>Build a strong community with Serpent Academy</p>
        </main>
        <nav>
          <Link to="/about">About</Link>
        </nav>
      </>
    );
  }
  export default Home;