import {Link} from 'react-router-dom'
import "./css/Menu.css"
import hamburger_icon from './img/hamburger.png';

import {useState} from 'react'
function Menu() {

  let [shouldHide, setShouldHide] = useState(false)
 function setHide(){

  setShouldHide(!shouldHide)
 }
    return (
      <div className="wrapper">

      <nav>
        <ul>
        <li  className='hamburger'><button className='hamburger'  onClick={setHide}><a className='hamburger'><img width="22px" src={hamburger_icon} alt='Menu'/></a></button></li>

            <li className='brand' ><a className='brand menuLinks active' href="/">Serpent Academy</a></li>
            <div className='mainMenuLinks'>
            <li className={shouldHide? 'hidden' : undefined}><a className='menuLinks inactive' href="/categories/">Categories</a></li>
            <li className={shouldHide? 'hidden' : undefined}><a className='menuLinks inactive' href="/labels/">Labels</a></li>
            <li className={shouldHide? 'hidden' : undefined}><a className='menuLinks inactive' href="/earn">Earn</a></li>

            <li className={shouldHide? 'hidden' : undefined}><a className='menuLinks inactive' href="/about">About</a></li>
            <div className="">

</div>
          </div>
        </ul>
      
      </nav>
    </div>
    );
  }
  export default Menu;