function About() {
    return (<>
    <h1>About Serpent Academy</h1>
    <ul>
<li>
    ✅ We are currently Building an Academy Learn to Earn 
</li>
<li>
    ✅ Our official NFT collection <a href="https://solsea.io/a/6148adffa71fbdd02113b013/collections/">Serpent Academy Collections on Solsea</a> 
</li>
<li>
    ✅ You can contact use at serpentnaturae@gmail.com 
</li>
    </ul>
    </>);
}

export default About;