function About() {
    return (<div className="About">
    <h1>About Learn to Earn in Serpent Academy</h1>
    <ul>
<li>We are building a Learn to Earn Academy</li>
<li>Power to people that study!</li>

    </ul>
    We come from nothing from a Third World Country, and struggle with money while studying robotics.
    <p>Coming Soon!</p>
    </div>);
}

export default About;