function Ad1() {
    return ( 
        <>
       
<ins className="adsbygoogle"

     style={{display:"block"}}
     data-ad-client="ca-pub-6809182904625645"
     data-ad-slot="7716647147"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>

</>
     );
}

export default Ad1;