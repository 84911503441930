import {Link} from 'react-router-dom'
import "./css/footer.css"
function Footer() {
    return (
      <>
      <div className="footer">
 <span>Created by <a href='https://serpent.academy'> Serpent Academy</a></span> 
 <br />
 <span><a href='https://serpent.academy'>Get our Official Serpent Academy NFTs </a></span> 

</div>
      </>
    );
  }
  export default Footer;