// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore, collection} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDT11E6uKtE0A3KpWp0dU6I3rn2W28Xsxc",
  authDomain: "serpent-blog-cceec.firebaseapp.com",
  projectId: "serpent-blog-cceec",
  storageBucket: "serpent-blog-cceec.appspot.com",
  messagingSenderId: "942973137981",
  appId: "1:942973137981:web:99e7a434a257a1acc4a654",
  measurementId: "G-FMEN1SJ86J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
export default getFirestore(app);